import React from "react"
import PropTypes from "prop-types"

const PlayerSettings = ({ settings = {}, onSettingChange = () => {} }) => {
  return <div>PlayerSettings</div>
}

PlayerSettings.propTypes = {
  settings: PropTypes.object,
  onSettingChange: PropTypes.func,
}

export default PlayerSettings
