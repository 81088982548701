import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"

import "./VideoPlayer.less"
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Row,
  Slider,
  Space,
} from "antd"
import {
  CloseOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
  RedoOutlined,
  RightOutlined,
  UndoOutlined,
} from "@ant-design/icons"
import PlayerSettings from "./PlayerSettings"
import { devLog, formatDuration, translatePath } from "../utils"
import { nanoid } from "nanoid"
import {
  FullscreenIcon,
  PlayIcon,
  ScreenLockIcon,
  SpeakerIcon,
  ToggleAudioOffIcon,
} from "./customIcons"
import { navigate } from "gatsby"
import { useBrowserDetector } from "../hooks/useBrowserDetector"
import RelatedProductList from "./RelatedProductList"
import IphoneVideoMask from "./IphoneVideoMask"
import { useTranslation } from "react-i18next"

const wingColSettingsRelated = {
  xs: 12,
  sm: 12,
  md: 0,
  lg: 4,
  xl: 4,
  xxl: 4,
}

const wingColSettingsTitle = {
  xs: 0,
  sm: 0,
  md: 18,
  lg: 16,
  xl: 16,
  xxl: 16,
}

const wingColSettingsActions = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  xl: 4,
  xxl: 4,
}

const VideoPlayer = ({
  videoSource,
  relatedProductList,
  autoStart = true,
  startAt,
  onPause,
  onPlay,
  onFinish,
  onSeekChange,
  onNext,
  onClose,
  onError,
  title,
  subtitle,
  nextVideo,
  onTimeUpdate,
  onFullscreenToggle,
  onPlaybackSpeedChange,
  onProductListOpen,
}) => {
  let mouseMoveTimeoutVar
  let previousTime

  const { t } = useTranslation()

  const [locked, setLocked] = useState(false) // block all elements to prevent accidental touch
  const [metaReady, setMetaReady] = useState(false)
  const [sessionId, setSessionId] = useState(() => nanoid())
  const [isPaused, setIsPaused] = useState(true)
  const [loading, setLoading] = useState(true)
  const [buffered, setBuffered] = useState()
  const [videoDuration, setVideoDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [playbackRate, setPlaybackRate] = useState(1)
  const [volume, setVolume] = useState(0)
  const [controlPanelClass, setControlPanelClass] = useState("")
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [videoDOM, setVideoDOM] = useState(null)
  const videoRef = createRef()
  const videoPlayerRef = createRef()
  const videoContainerMobileRef = createRef()
  const videoContainerDesktopRef = createRef()
  const isPauseRef = useRef(isPaused)
  const { isMobile, isSmallScreen, isIphone, isIos } = useBrowserDetector()
  const [hideVideo, setHideVideo] = useState(false)

  isPauseRef.current = isPaused

  const tryPlayVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {})
        .catch(error => {
          devLog({ videoPlayError: error })
        })
    }
  }, [videoRef])

  const onPageClose = evt => {
    handleVideoClose()
    evt.preventDefault()
    evt.returnValue = ""
    return t("warning:leavePageMessage")
  }

  const onMouseMove = () => {
    setControlPanelClass("")
    if (mouseMoveTimeoutVar) {
      clearTimeout(mouseMoveTimeoutVar)
    }

    mouseMoveTimeoutVar = setTimeout(() => {
      if (!isPauseRef.current) {
        setControlPanelClass("hidden")
      }
    }, 2000)
  }

  useEffect(() => {
    const _videoDOM = videoRef.current
    bindVideoEvents(_videoDOM)
    setVideoDOM(_videoDOM)

    if (startAt) {
      handleSeekChange(startAt)
    }

    return () => {
      unbindVideoEvents()
    }
  }, [])

  useEffect(() => {
    onPlaybackSpeedChange &&
      onPlaybackSpeedChange({
        currentTime,
        sessionId,
        playbackSpeed: playbackRate,
      })
  }, [playbackRate])

  useEffect(() => {
    if (
      videoRef.current &&
      (videoContainerDesktopRef.current || videoContainerMobileRef.current)
    ) {
      const videoContainer =
        videoContainerDesktopRef.current || videoContainerMobileRef.current
      if (videoRef.current.parentElement !== videoContainer) {
        videoContainer.appendChild(videoRef.current)
      }
    }
  }, [videoContainerDesktopRef, videoContainerMobileRef, videoRef])

  useEffect(() => {
    if (videoRef.current?.src) {
      videoRef.current.load()
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      if (videoRef.current.src !== videoSource) {
        videoRef.current.src = videoSource
        videoRef.current.load()
        videoRef.current.currentTime = currentTime
      }
    }
  }, [videoSource, videoRef])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate
    }
  }, [playbackRate, videoRef])

  useEffect(() => {
    if (isPaused) {
      setControlPanelClass("")
    }

    if (videoRef.current) {
      try {
        if (isPaused) {
          videoRef.current.pause()
        } else {
          tryPlayVideo()
        }
      } catch (err) {
        devLog({ err })
      }
    }
  }, [isPaused, videoRef])

  const bindKeyboardShortcuts = () => {}

  const unbindKeyboardShortcuts = () => {}

  const _onCanPlay = evt => {
    devLog({ onCanPlay: evt })
    // pronto video, non è detto che con la connessione attuale puo eseguire in modo fluido il video. Usare `canplaythrough`
  }

  const _onCanPlayThrough = evt => {
    // il video puo lanciare e buffering è in un ottimo stato.
    devLog({ onCanPlayThrough: evt })

    setLoading(false)
    if (autoStart) {
      try {
        setIsPaused(false)
        tryPlayVideo()
      } catch (error) {
        devLog({ error })
      }
    }
  }

  const _onEnded = evt => {
    const currentTime = evt.target.currentTime

    onFinish && onFinish({ sessionId, currentTime })
  }

  const _onError = evt => {
    // uiHandleError({ error })
    onError && onError(evt.target.error)
  }

  const _onMetadataLoaded = evt => {
    const videoElement = evt.target
    setVideoDuration(videoElement.duration)
    setVolume(videoElement.volume * 100)
    setMetaReady(true)
  }

  const _onPause = evt => {
    const currentTime = evt.target.currentTime
    setIsPaused(true)
    onPause && onPause({ currentTime, sessionId })
  }

  const _onPlay = evt => {
    // setIsPaused(false)
    const currentTime = evt.target.currentTime
    // tryPlayVideo()
    onPlay && onPlay({ currentTime, sessionId })
  }

  const _onPlaying = evt => {
    // setIsPaused(false)
    // tryPlayVideo()
  }

  const _onProgress = evt => {
    //TODO: tramite markers di antd per mostrare lo stato di buffering
    const videoElement = evt.target
    setBuffered(videoElement.buffered)
  }

  const _onSeeking = evt => {
    const videoElement = evt.target
    onSeekChange &&
      onSeekChange({
        previousTime,
        sessionId,
        currentTime: videoElement.currentTime,
      })
  }

  const _onStalled = () => {
    // uiHandleError({ error: new Error('Stalled') })
  }

  const _onTimeUpdate = evt => {
    previousTime = evt.target.currentTime
    setCurrentTime(evt.target.currentTime)
    onTimeUpdate &&
      onTimeUpdate({
        sessionId,
        currentTime: evt.target.currentTime,
        target: evt.target,
      })
  }

  const _onWaiting = () => {
    setLoading(true)
    setIsPaused(true)
  }

  const bindVideoEvents = video => {
    video.addEventListener("canplay", _onCanPlay)
    video.addEventListener("canplaythrough", _onCanPlayThrough)
    video.addEventListener("ended", _onEnded)
    video.addEventListener("error", _onError)
    video.addEventListener("loadedmetadata", _onMetadataLoaded)
    video.addEventListener("pause", _onPause)
    video.addEventListener("play", _onPlay)
    video.addEventListener("playing", _onPlaying)
    video.addEventListener("progress", _onProgress)
    video.addEventListener("seeking", _onSeeking)
    video.addEventListener("stalled", _onStalled)
    video.addEventListener("timeupdate", _onTimeUpdate)
    video.addEventListener("waiting", _onWaiting)

    document.addEventListener("mousemove", onMouseMove, false)
    window.addEventListener("beforeunload", onPageClose)

    bindKeyboardShortcuts()
  }

  const unbindVideoEvents = () => {
    if (videoRef.current) {
      videoRef.current.removeEventListener("canplay", _onCanPlay)
      videoRef.current.removeEventListener("canplaythrough", _onCanPlayThrough)
      videoRef.current.removeEventListener("ended", _onEnded)
      videoRef.current.removeEventListener("error", _onError)
      videoRef.current.removeEventListener("loadedmetadata", _onMetadataLoaded)
      videoRef.current.removeEventListener("pause", _onPause)
      videoRef.current.removeEventListener("play", _onPlay)
      videoRef.current.removeEventListener("playing", _onPlaying)
      videoRef.current.removeEventListener("progress", _onProgress)
      videoRef.current.removeEventListener("seeking", _onSeeking)
      videoRef.current.removeEventListener("stalled", _onStalled)
      videoRef.current.removeEventListener("timeupdate", _onTimeUpdate)
      videoRef.current.removeEventListener("waiting", _onWaiting)
    }

    document.removeEventListener("mousemove", onMouseMove, false)
    window.removeEventListener("beforeunload", onPageClose)

    unbindKeyboardShortcuts()
  }

  const togglePlayVideo = () => {
    setIsPaused(!isPaused)
    if (isPaused) {
      tryPlayVideo()
    }
  }

  const playVideo = () => {
    setIsPaused(false)
    tryPlayVideo()
  }

  const handleSeekChange = value => {
    if (videoRef) {
      videoRef.current.currentTime = value
      setCurrentTime(value)
    }
  }

  const handleVolumeChange = value => {
    if (videoRef) {
      videoRef.current.volume = value / 100.0
      setVolume(value)
    }
  }

  const handleMaskClick = () => {
    togglePlayVideo()
  }

  const toggleFullscreen = () => {
    let fullscreen
    if (document.fullscreenElement) {
      document.exitFullscreen()
      fullscreen = false
    } else {
      let fullscreenElement = videoPlayerRef.current
      if (isMobile() || isSmallScreen()) {
        fullscreenElement = videoRef.current
      }
      if (fullscreenElement && fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen()
        fullscreen = true
      }
    }

    onFullscreenToggle({ currentTime, sessionId, fullscreen })
  }

  const toggleMute = () => {}

  const toggleVideo = () => {
    setHideVideo(!hideVideo)
  }

  const goForward = () => {
    const newTime = Math.min(videoDuration, currentTime + 5)
    handleSeekChange(newTime)
  }

  const goBackward = () => {
    const newTime = Math.max(0, currentTime - 5)
    handleSeekChange(newTime)
  }

  const handleVideoClose = () => {
    // TODO get video played time

    onClose && onClose({ currentTime, sessionId })
  }

  const showSettings = () => {
    setShowSettingsModal(true)
  }

  const hideSettings = () => {
    setShowSettingsModal(false)
  }

  const playButton = loading ? (
    <LoadingOutlined />
  ) : isPaused ? (
    <PlayIcon />
  ) : (
    <PauseCircleOutlined />
  )

  const playbackRateMenu = useMemo(
    () => (
      <Menu
        activeKey={`playback-rate-${playbackRate}`}
        onClick={evt => {
          const key = evt.key
          setPlaybackRate(Number(key.replace("playback-rate-", "")))
        }}
      >
        <Menu.Item key={`playback-rate-0.5`}>0.5x</Menu.Item>
        <Menu.Item key={`playback-rate-1`}>1.0x</Menu.Item>
        <Menu.Item key={`playback-rate-1.5`}>1.5x</Menu.Item>
        <Menu.Item key={`playback-rate-2`}>2.0x</Menu.Item>
      </Menu>
    ),
    []
  )

  const relatedProductListMemo = useMemo(
    () => <RelatedProductList data={relatedProductList} />,
    [relatedProductList]
  )

  const getDropdownContainer = () => {
    return videoPlayerRef.current
  }

  return (
    <div>
      <video src={videoSource} ref={videoRef} />
      {isSmallScreen() ? (
        <div
          className={`video-player mobile ${locked ? "locked" : ""}`}
          ref={videoPlayerRef}
        >
          <Row justify={"space-between"} align="middle">
            <Col span={22} className="title">
              {title}
            </Col>
            <Col span={2} className="close">
              <Space>
                <CloseOutlined onClick={handleVideoClose} />
              </Space>
            </Col>
          </Row>
          <div ref={videoContainerMobileRef} />

          <div className="video-timeline">
            <Slider
              min={0}
              max={videoDuration}
              value={currentTime}
              step={0.01}
              onChange={handleSeekChange}
              tipFormatter={value => formatDuration(value)}
            />
            <Row justify={"space-between"}>
              <Col>{formatDuration(currentTime)}</Col>
              <Col>{formatDuration(videoDuration)}</Col>
            </Row>
          </div>

          {isIphone() ? (
            <IphoneVideoMask
              loading={!metaReady}
              onClick={playVideo}
              title={title}
            />
          ) : (
            <div className="player-button-wrapper">
              <Row>
                <Col span={8}>
                  <UndoOutlined onClick={goBackward} />
                </Col>
                <Col span={8}>
                  {
                    <div className={"play-button"} onClick={togglePlayVideo}>
                      {playButton}
                    </div>
                  }
                </Col>
                <Col span={8}>
                  <RedoOutlined onClick={goForward} />
                </Col>
                <Col span={24}>
                  <Divider direction="horizontal" />
                </Col>
              </Row>
            </div>
          )}

          <div className="player-playback-rate-wrapper">
            <Row justify="center">
              <Col span={6}>
                <a
                  className={playbackRate === 0.5 ? "active" : ""}
                  onClick={() => {
                    setPlaybackRate(0.5)
                  }}
                >
                  0.5x
                </a>
              </Col>
              <Col span={6}>
                <a
                  className={playbackRate === 1 ? "active" : ""}
                  onClick={() => {
                    setPlaybackRate(1)
                  }}
                >
                  1x
                </a>
              </Col>
              <Col span={6}>
                <a
                  className={playbackRate === 1.5 ? "active" : ""}
                  onClick={() => {
                    setPlaybackRate(1.5)
                  }}
                >
                  1.5x
                </a>
              </Col>
              <Col span={6}>
                <a
                  className={playbackRate === 2 ? "active" : ""}
                  onClick={() => {
                    setPlaybackRate(2)
                  }}
                >
                  2x
                </a>
              </Col>
              <Col span={24}>
                <Divider direction="horizontal" />
              </Col>
            </Row>
          </div>

          <div className="player-advcontrol-wrapper">
            <Row>
              <Col span={6}>
                <Dropdown
                  overlay={
                    <div className="volume-slider-wrapper">
                      <Slider
                        vertical
                        min={0}
                        max={100}
                        value={volume}
                        onChange={handleVolumeChange}
                      />
                    </div>
                  }
                  placement="topCenter"
                >
                  <SpeakerIcon onClick={toggleMute} />
                </Dropdown>
              </Col>
              <Col span={6}>
                <ToggleAudioOffIcon
                  onClick={toggleVideo}
                  className={hideVideo ? "active" : ""}
                />
              </Col>
              <Col span={6}>
                <ScreenLockIcon
                  className={`btn-lock ${locked ? "active" : ""}`}
                  onClick={() => {
                    // notification.warning({
                    //   // message: "Notification Title",
                    //   placement: "bottomRight",
                    //   description: !locked
                    //     ? "I pulsanti sono disabilitati"
                    //     : "I pulsanti sono riabilitati",
                    // })
                    setLocked(!locked)
                  }}
                />
              </Col>
              <Col span={6}>
                <FullscreenIcon onClick={toggleFullscreen} />
              </Col>
              <Col span={24}>
                <Divider direction="horizontal" />
              </Col>
            </Row>
          </div>
          {relatedProductListMemo}
        </div>
      ) : (
        <div className={"video-player"} ref={videoPlayerRef}>
          <div className="video-container" ref={videoContainerDesktopRef} />
          <div className={`video-control-panel ${controlPanelClass}`}>
            <div className={"mask"} onClick={handleMaskClick} />
            <div className={"top-bar"}>
              <Row justify="space-between">
                <Col {...wingColSettingsRelated}>{relatedProductListMemo}</Col>
                <Col {...wingColSettingsTitle}>
                  <div className="title-wrapper">
                    <h2>{title}</h2>
                    <h4>{subtitle}</h4>
                  </div>
                </Col>
                <Col {...wingColSettingsActions}>
                  <Row justify={"end"}>
                    <div className="top-bar-actions-wrapper">
                      <Space size={16}>
                        <Dropdown
                          getPopupContainer={getDropdownContainer}
                          overlay={
                            <div className="volume-slider-wrapper">
                              <Slider
                                vertical
                                min={0}
                                max={100}
                                value={volume}
                                onChange={handleVolumeChange}
                              />
                            </div>
                          }
                          placement="bottomCenter"
                        >
                          <SpeakerIcon onClick={toggleMute} />
                        </Dropdown>
                        <Dropdown
                          overlay={playbackRateMenu}
                          getPopupContainer={getDropdownContainer}
                          placement="bottomCenter"
                        >
                          <Button>{playbackRate.toFixed(1)}x</Button>
                        </Dropdown>
                        {/*<SettingsIcon onClick={showSettings} />*/}
                        <FullscreenIcon onClick={toggleFullscreen} />
                        <Divider direction="vertical" />
                        <CloseOutlined onClick={handleVideoClose} />
                      </Space>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className={"play-button"} onClick={togglePlayVideo}>
              {playButton}
            </div>
            <div className="video-control-toolbar">
              <div className="video-timeline">
                <Slider
                  min={0}
                  max={videoDuration}
                  value={currentTime}
                  step={0.01}
                  onChange={handleSeekChange}
                  tipFormatter={value => formatDuration(value)}
                />
              </div>
              <Row justify="space-between">
                <Col>
                  {formatDuration(currentTime)} /{" "}
                  {formatDuration(videoDuration)}
                </Col>
                <Col>
                  {nextVideo && (
                    <Button
                      type="ghost"
                      onClick={() => {
                        onNext && onNext()
                        setSessionId(nanoid())
                        navigate(`${translatePath("/watch")}/${nextVideo}`)
                      }}
                    >
                      Episodio successivo <RightOutlined />
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <Modal
            visible={showSettingsModal}
            onCancel={hideSettings}
            onOk={hideSettings}
          >
            <PlayerSettings />
          </Modal>
        </div>
      )}
    </div>
  )
}

VideoPlayer.propTypes = {
  videoSource: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  relatedProductList: PropTypes.arrayOf(PropTypes.object),
  autoStart: PropTypes.bool,
  startAt: PropTypes.number,
  onPause: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onResume: PropTypes.func,
  onStart: PropTypes.func,
  onFinish: PropTypes.func,
  onSeekChange: PropTypes.func,
  onPlayerUnload: PropTypes.func,
  onClose: PropTypes.func,
  nextVideo: PropTypes.string,
}

VideoPlayer.defaultProps = {}

export default VideoPlayer
