export const START = "START"
export const STOP = "STOP"
export const PAUSE = "PAUSE"
export const SEEK = "SEEK"
export const FINISH = "FINISH"
export const BACKWARD = "BACKWARD"
export const FORWARD = "FORWARD"
export const SET_SPEED = "SET_SPEED"
export const MUTE = "MUTE"
export const UNMUTE = "UNMUTE"
export const DISABLE_VIDEO = "DISABLE_VIDEO"
export const ENABLE_VIDEO = "ENABLE_VIDEO"
export const LOCK_CONTROLS = "LOCK_CONTROLS"
export const UNLOCK_CONTROLS = "UNLOCK_CONTROLS"
export const ENTER_FULLSCREEN = "ENTER_FULLSCREEN"
export const EXIT_FULLSCREEN = "EXIT_FULLSCREEN"
