import React from "react"
import "./IphoneVideoMask.less"
import { LoadingOutlined, PlayCircleFilled } from "@ant-design/icons"

const IphoneVideoMask = ({ loading, onClick }) => {
  return (
    <div className="iphone-video-mask-wrapper">
      <div className="content">
        {loading ? <LoadingOutlined /> : <PlayCircleFilled onClick={onClick} />}
      </div>
    </div>
  )
}

export default IphoneVideoMask
